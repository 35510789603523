(function($){
	$(function() {

		if($('#agenda-control').length > 0) {
			var app = new Vue({
				el: '#agenda-control',
				mounted: function () {

					var self = this;

					//based on the query param start= or start with a clean slate
					var paramStart = self.getParam('start');
					if(paramStart !== '') {
						self.setDate(paramStart);
					}

					//fetch the current data
					self.fetchData();

					//get the week numbers
					self.updateWeekNrs();

				},
				data: {
					agenda: $('#agenda-control').data('agenda'),
					url: $('#agenda-control').data('url'),
					items: {},
					weeks: [],
					startDate: moment().weekday(1),
					endDate: moment().weekday(7),
					currentDate: moment(),
					prevDate: moment().subtract(1, 'w'),
					nextDate: moment().add(1, 'w')
				},
				// define methods under the `methods` object
				methods: {
					setDate : function(dateString){
						var self = this;
						self.currentDate = moment(dateString);
						self.startDate = moment(dateString).weekday(1);
						self.endDate = moment(dateString).weekday(7);
						self.prevDate = moment(dateString).subtract(1, 'w');
						self.nextDate = moment(dateString).add(1, 'w');
					},
					nextWeek : function(){
						var self = this;
						self.startDate.add(1, 'w');
						self.currentDate.add(1, 'w');
						self.endDate.add(1, 'w');
						self.updateWeekNrs();
						self.fetchData();
					},
					prevWeek : function(){
						var self = this;
						self.startDate.subtract(1, 'w');
						self.currentDate.subtract(1, 'w');
						self.endDate.subtract(1, 'w');
						self.updateWeekNrs();
						self.fetchData();
					},
					updateWeekNrs: function(){
						var self = this;

						self.prevDate = self.currentDate.clone().subtract(1, 'w');
						self.nextDate = self.currentDate.clone().add(1, 'w');

						//get the week numbers
						self.weeks = [];
						self.weeks.push(self.prevDate.format('w'));
						self.weeks.push(self.currentDate.format('w'));
						self.weeks.push(self.nextDate.format('w'));
					},
					fetchData: function(){
						var self = this;

						//set the query string
						self.setParam('start', self.currentDate.format('YYYY-MM-DD'));

						//show the loader
						$('.schedule-loader').show();

						//get the data
						$.get('/agenda-items/ajax', {
							start: self.currentDate.format('DD-MM-YYYY'),
							agenda_site: self.agenda,
							url: self.url,
						}, function(data){
							//assing the items0
							self.items = JSON.parse(data);

							//hide the loader
							$('.schedule-loader').fadeOut();
						});
					},
					getParam: function(key) {
						var query =  document.location.search.replace('?', '').split('&');
						var value = '';
						$.each(query, function(index, val) {
							var find = val.split('=');
							if(find[0] === key) {
								value = find[1];
							}
						});

						return value;
					},
					setParam: function(key, value) {
						var baseUrl = [location.protocol, '//', location.host, location.pathname].join(''),
							urlQueryString = document.location.search,
							newParam = key + '=' + value,
							params = '?' + newParam;

						// If the "search" string exists, then build params from it
						if (urlQueryString) {
							var updateRegex = new RegExp('([\?&])' + key + '[^&]*');
							var removeRegex = new RegExp('([\?&])' + key + '=[^&;]+[&;]?');

							if( typeof value === 'undefined' || value == null || value === '' ) { // Remove param if value is empty
								params = urlQueryString.replace(removeRegex, "$1");
								params = params.replace( /[&;]$/, "" );

							} else if (urlQueryString.match(updateRegex) !== null) { // If param exists already, update it
								params = urlQueryString.replace(updateRegex, "$1" + newParam);

							} else { // Otherwise, add it to end of query string
								params = urlQueryString + '&' + newParam;
							}
						}

						// no parameter was set so we don't need the question mark
						params = params === '?' ? '' : params;

						window.history.replaceState({}, "", baseUrl + params);
					}
				}
			});

			//event when clicking the calendar and select a date from it.
			$('.select-week').datepicker().on('changeDate', function(e) {
				app.setDate(e.date);
				app.updateWeekNrs();
				app.fetchData();
			});
		}
		
	});
})(jQuery);
