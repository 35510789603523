$(document).ready(function () {
    matchHeight();
    handleCarousel();
    gallery();
    handleTable();
    handleQuicklink();
    convertToTap();

    var tag = document.createElement('script');

    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);


    $(".video-wrapper").each(function (index, element) {
        var videoWrapper = $(element);
        $(element).on('click', function (index, element) {
            var youtubeHash = $("#player").attr("data-youtube-hash");

            $(videoWrapper).toggleClass("pullout");
            $("body").toggleClass("fixed");

            if ( $('.video-wrapper').hasClass('pullout') ) {
                $('.video-close').removeClass('invisible');
                $('.video-close').addClass('visible');
            }

            if ( !$('.video-wrapper').hasClass('pullout') ) {
                $('.video-close').removeClass('visible');
                $('.video-close').addClass('invisible');
            }

            if($("#player").is("iframe")){
                $("#player").replaceWith("<div id='player' data-youtube-hash='"+ youtubeHash +"'></div>");
            } else {
                var player = new YT.Player('player', {
                    height: '390',
                    width: '640',
                    videoId: youtubeHash,
                    events: {
                        'onReady': onPlayerReady,
                        'onPlaying' : onPlaying,
                        'onclick' : onPlayerClick
                    }
                });
            }

        });

        function onPlayerClick(event){
            event.target.stopVideo();
        }

        function onPlayerReady(event) {
            event.target.playVideo();
        }

        function onPlaying(event) {
            event.target.stopVideo();
        }
    });

    $(document).ready(function(){
        $('#nav-icon').click(function(){
            // $(this).toggleClass('open');
        });
    });

    $('body').addClass("loading");

    $(window).resize(function() {
        handleQuicklink();
    });
});

$(window).on("load", function() {

    handleFooter();
    $(window).trigger('resize');
});

function handleQuicklink() {
    var element = $('.quicklink');
    var tileWidth = element.outerWidth( true );
    var tileHeight = tileWidth;

    element.height(tileHeight)
}

function matchHeight() {
    $('article .block-title').matchHeight();
}

function handleCarousel() {
    var uspCarousel = $("#uspCarousel");
    uspCarousel.owlCarousel({
        items:1,
        dots: false,
        autoplay: true,
        loop: true,
        mouseDrag: false,
        margin: 40,
        responsive: {
            0:{
                items:1
            },
            768: {
                items:3,
                autoplay: false,
                loop: false,
                autoWidth:true
            }
        }
    })

    // $('.owl-carousel').owlCarousel({
    //     items:1
    // });
}

function gallery() {

    $(".gallery").each(function(index,item) {

        $(this).magnificPopup({
            delegate: 'a',
            type: 'image',
            tLoading: 'Loading image #%curr%...',
            mainClass: 'mfp-img-mobile mfp-fade',
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
                tCounter: '%curr% van %total%' // Markup for "1 of 7" counter
            },
            image: {
                tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
                titleSrc: function (item) {
                    return item.el.attr('title');
                }
            },
            removalDelay: 300
        });

        var galleryLength = $(item).find("a").length;

        if (galleryLength == 1) {
            $(item).addClass('one-image');
        } else if (galleryLength == 2) {
            $(item).addClass('two-image');
        } else if (galleryLength == 3) {
            $(item).addClass('three-image');
        } else if (galleryLength >= 4) {
            $(item).addClass('more');
        }
    });
}

window.onload = function() {

    var placeholder = $("figure.figure-progressive");

    placeholder.each(function() {
        var small = this.querySelector('.img-small');

        //load small image and show it
        var img = new Image();
        img.src = small.src;
        img.onload = function () {
            small.classList.add('loaded');
        };

        //load large image
        var imgLarge = new Image();
        imgLarge.src = this.dataset.large;
        imgLarge.srcset = this.dataset.srcset;
        imgLarge.onload = function () {
            imgLarge.classList.add('loaded');
        };
        this.appendChild(imgLarge);

    });
};

function handleFooter() {
    $(window).resize(function() {
        var contentHeight = Math.round($("body").innerHeight());
        var windowWidth= $(window).innerWidth();
        var windowHeight = window.innerHeight;

        if((windowWidth >= 768) && ( contentHeight <= windowHeight)){
            $("footer").addClass("sticky");
        } else {
            $("footer").removeClass("sticky");
        }
    });
}

function handleTable() {
    $('table').addClass('table table-striped');
}

jQuery(function($) {
    $(".card-header").click(function() {
        $(this).find(".arrow-up, .arrow-down").toggleClass();
    });

});

function convertToTap() {
    $(".tabs").each(function (index, element) {

        var tabsContainer = $(element).find(".tab-container");
        var tabsComponent = tabsContainer.find(".tab-component");
        var accordionElement = $('<div class="accordion" id="accordionComponent"></div>');

        $(accordionElement).appendTo(tabsContainer);

        var $newAccordionItemTitle = new Array();
        var $newAccordionItemText = new Array();
        //
        tabsComponent.find(".nav-tabs .nav-item").each(function (index, element) {
            $newAccordionItemTitle.push(element.text);
            $(accordionElement).append('<div class="card"><div class="card-header" id="heading'+ index +'" data-toggle="collapse" data-target="#collapse'+ index +'" aria-controls="collapse'+ index +'">' + $newAccordionItemTitle[index] +'<span class="arrow"> > </span></div></div>');

        });

        tabsComponent.find(".tab-content .tab-pane").each(function (index, element) {
            $newAccordionItemText.push($(this).text());
            accordionElement.find('.card:nth-child(' + (index + 1) +')').append('<div class="collapse" id="collapse'+ index +'" data-parent="#accordionComponent"><div class="card-body">'+ $newAccordionItemText[index] +'</div></div>');
            accordionElement.find('.card:first-child .card-header').attr("aria-expanded","true");
            accordionElement.find('.card:first-child .collapse').addClass("show");
        });

        $(window).resize(function() {
            var screenSize = $( window ).outerWidth();
            if (screenSize < 1024) {
                $(accordionElement).appendTo(tabsContainer);
                $(tabsComponent).detach();
            }
            else {
                $(tabsContainer).find(accordionElement).detach();
                $(tabsComponent).appendTo(tabsContainer);
            }
        });

    });
}