/* Set the width of the side navigation to 250px and the left margin of the page content to 250px and add a black background color to body */
function openNav() {
    document.getElementById('page-wrapper').classList.add('mobile-menu-active');
    document.getElementById('nav-icon').classList.add('open');
}

/* Set the width of the side navigation to 0 and the left margin of the page content to 0, and the background color of body to white */
function closeNav() {
    document.getElementById("page-wrapper").classList.remove('mobile-menu-active');
    document.getElementById('nav-icon').classList.remove('open');
}

function goBack() {
    $(".sidenav-panel").removeClass("sidenav-panel_open");
}

function toggleSearchBox() {
    $(".sidenav-searchbox").toggleClass("sidenav-searchbox_open");
}

$(document).ready(function () {

    var $menu = '<div class="sidenav" id="mySidenav">\n' +
        '          <div class="sidenav-header">Menu\n' +
        '            <div class="sidenav-control"><a class="closebtn" href="javascript:void(0)" onclick="closeNav()"><span>×</span></a></div>\n' +
        '          </div>\n' +
        '          <div class="sidenav-panels">\n' +
        '            <div class="sidenav-panel" id="panel-menu">\n' +
        '               <ul>' + $( "#nav-main" ).html() + '</ul>\n' +
        '            </div>\n' +
        '          </div>\n' +
        '        </div>';

    $($menu).appendTo( "#page-wrapper" );

    $("#panel-menu ul li.dropdown").each(function(index,value){

        var subItems = $(value).html();

        var panel = '<div id="panel-'+ index +'" class="sidenav-panel sidenav-panel-sub"><a href="javascript:void(0)" onclick="goBack()" class="backbtn"><span>&lsaquo;</span> Terug</a><ul>'+ subItems +'</ul></div>';
        $(".sidenav-panels").append(panel);

        $(this).find('.dropdown-toggle').click(function(e){
            $("#panel-"+index).addClass("sidenav-panel_open");
            $(value).parents("#panel-menu").addClass("sidenav-panel_open");
        });
    });
});